import React from "react";
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import proj4 from 'proj4'
import {useMsal} from "@azure/msal-react";
import {useTranslation} from 'react-i18next';
import MultiChoiceSearchDropdown from "../widgets/MultiChoiceSearchDropdown/MultiChoiceSearchDropdown";
import ModalComponent from "../widgets/ModalComponent/ModalComponent";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ViewPlace from "./ViewPlace";
import {AxiosError} from "axios";
import {
    GetCodeClasses,
    UpdateDestination,
    DeleteDestination,
    InsertDestination,
    GetLandUseUnitsData,
    GetUserCreatedDestinations,
    GetCountingsByDestinationId,
    GetFlows,
    GetFlowsBySiteId,
    GetSitesByDestinationId, GetCountsByFlowId, DestinationDeleteAllowed
} from '../utils/NastaApiClient';
import {useLocation} from 'react-router-dom'


import { useSelector, useDispatch, useStore} from 'react-redux';
import * as codesSelector from '../mapViewOl/codesSelector';

import './AddPlace.css';
import ValidatedInputField from "../widgets/ValidatedInputField/ValidatedInputField";
import close_fill from '../icons/close_fill.svg';
import {Destination} from "../dataClasses/Destination/Destination";
import Button from "@mui/material/Button";
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import UploadingNotification from "../widgets/UploadingNotification/UploadingNotification";
import {Flow} from "../dataClasses/Flow";
import {Site} from "../dataClasses/Site/Site";


const withRouter = WrappedComponent => props => {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();
    const {instance} = useMsal();
    const location = useLocation();
    const {t} = useTranslation('addPlace', 'common');
    const navigate = useNavigate();
    const codes = useSelector((state) => state.codes);
    const dispatch = useDispatch();
    const storeHelper = useStore();

    return (
        <WrappedComponent
            t={t}
            location={location}
            {...props}
            params={params}
            {...{searchParams, setSearchParams, instance, /* other injected props */}}
            let navigate={navigate}
            dispatch={dispatch}
            storeHelper={storeHelper}
            codes={codes}
        />
    );
};

proj4.defs([
    ['EPSG:3067', '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs'],
    ['EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs +type=crs']
]);


export class AddPlace extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            submitAttempted: false,
            errors:[],
            showErrorModal: false,
            showSubmitAlert: false,
            showSubmitModal: false,
            showEditingModal: false,
            viewMode: typeof props !== 'undefined' && typeof props.boundData.viewMode !== 'undefined' ? props.boundData.viewMode : 1,
            choices: [],
            currentSearchValue: "",
            landUseUnits: [],
            selectedLandUseUnits: [],
            clearLandUseUnits: false,
            canBeDeleted:false,
            childFlowsWithCounts: [],
            countingsWithSameDestinations: [],
            childSites:[],
            destination:
                {
                    destinationId: 0,
                    destinationTypeCodeId: 0,
                    destinationTypeCodeClassId: 0,
                    destinationName: "",
                    destinationOwnerCodeId: 0,
                    destinationOwnerCodeClassId: 0,
                    destinationFunderCodeId: 0,
                    destinationFunderCodeClassId: 0,
                    parkDistrictCodeId: 0,
                    parkDistrictCodeClassId: 0,
                    entityCodeId: 0,
                    entityCodeClassId: 0,
                    regionalUnitTypeCodeId: 0,
                    regionalUnitTypeCodeClassId: 0,
                    description: "",
                    customerServiceName: "",
                    customerServiceNumber: 0,
                    customerServiceTypeCodeId: 0,
                    customerServiceTypeCodeClassId: 0,
                    inUse: true,
                    createdBy: 'string',
                    modifiedBy: 'string',
                    municipalityCodeId: [],
                    municipalityCodeClassId: 0,
                    countyCodeId: [],
                    countyCodeClassId: 0,
                    coordinate:
                        {
                            coordinateId: 0,
                            x_Coordinate: 0,
                            y_Coordinate: 0,
                            n_Coordinate: 0,
                            e_Coordinate: 0,
                            destinationId: 0,
                            destination: "string"
                        }
                },
            destinationValid:
                {
                    destinationId: true,
                    destinationName: false,
                    description: false,
                    customerServiceName: false,
                    parkDistrict: false,
                    regionalUnit: false,
                    customerServiceType: false,
                    entity: false,
                    destinationOwnerCodeId:false,
                    destinationTypeCodeId:false,
                    destinationFunderCodeId:false
                },
            destinationRequired: {
                destinationId: false,
                destinationName: true,
                description: false,
                customerServiceName: false,
                destinationOwnerCodeId:true,
                destinationTypeCodeId:true,
                destinationFunderCodeId:true,
                customerServiceType:false
            },
            destinationTouched:
                {
                    destinationId: true,
                },
            editingState: false,
            codeClassesLoaded: false,
            codeClasses: [],
            editSuccesfulModalOpen:false,
            destinationDeletionNotAllowedVisible:false,
            deletionSuccesfullModalOpen:false,
            deleteButtonVisible:true,
            submitErrorsModalOpen: false,
            submitErrorsModalTitle: "",
            submitErrorsModalContent: ""
        }

        this.loadData=this.loadData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCoords = this.handleCoords.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.GenQuickMultiSearchDropdown = this.GenQuickMultiSearchDropdown.bind(this);
        this.resetData = this.resetData.bind(this);
        this.GetLandUseUnitList = this.GetLandUseUnitList.bind(this);
        this.checkDependencies=this.checkDependencies.bind(this);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.boundData.visibility === false && prevProps.visible===true) {
            this.props.onVisibilityChanged([false, true]);
        }


        if(typeof this.props.boundData.destinationName === 'undefined' && this.props.visible!==false) {

            if(typeof this.props.visible !== 'undefined'){
                this.props.onVisibilityChanged([false,false]);
            }

        }

        if(typeof this.props.boundData.viewMode!=='undefined'){
            if(this.props.boundData.viewMode!==this.state.viewMode){
                this.setState({viewMode: this.props.boundData.viewMode})
            }
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData(){
        if (typeof this.props.params.id !== 'undefined') {
            try{
                GetUserCreatedDestinations().then(destinations => {
                    let dest = destinations.data.find(x=>{
                        return x.properties.destinationId===parseInt(this.props.params.id);
                    });

                    this.setState({destination: dest.properties});
                });
            }catch (error) {
                console.log(error);
            }
        }

        this.setState(state => {
            state.nonce = crypto.randomUUID();
            state.destination.createdBy = typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : '';
            state.destination.modifiedBy = typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : '';
            return state

        });

        try {
            if(!this.props.storeHelper.getState().codesSelector.codes.codeClassesLoaded){
                let response = await GetCodeClasses();

                if (response instanceof AxiosError) {
                    this.setState({error: response});
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(false));
                } else {
                    this.props.dispatch(codesSelector.fillCodeClasses(response));
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(true));
                }
            }
        } catch (error) {
            console.log(error);
        }
        try {

            let response = await GetLandUseUnitsData();
            if (response instanceof AxiosError) {
                this.setState({error: response});
            } else {
                this.setState({landUseUnits: response.data}, ()=>{

                    let update = [];
                    if(typeof this.props.boundData !== 'undefined' && this.props.boundData !== null && typeof this.props.boundData.landUseUnitId !== 'undefined' && this.props.boundData.landUseUnitId !== null){
                        let landUseUnit = response.data.find(x => x.landUseUnitId === this.props.boundData.landUseUnitId);
                        if(typeof landUseUnit !== 'undefined'){
                            update.push(landUseUnit);
                        }
                    }
                    if(typeof this.props.boundData !== 'undefined' && this.props.boundData !== null && typeof this.props.boundData.landUseUnitCodes !== 'undefined' && this.props.boundData.landUseUnitCodes !== null){
                        this.props.boundData.landUseUnitCodes.forEach(luid => {
                            let landUseUnit = response.data.find(x => x.landUseUnitId === luid.LandUseUnitId);
                            if(typeof landUseUnit !== 'undefined'){
                                update.push(landUseUnit);
                            }
                        });
                    }
                    this.setState({selectedLandUseUnits: update});

                });
            }
        }
        catch (error) {
            console.log(error);
        }

        let boundOrPropsId = typeof this.props.boundData.destinationId!=='undefined'?this.props.boundData.destinationId:this.props.params.id;

        let canBeDeleted=false;
        if(typeof boundOrPropsId !== 'undefined'){
            canBeDeleted = await DestinationDeleteAllowed(boundOrPropsId);
            this.setState({canBeDeleted:canBeDeleted})
        }else{
            this.setState({
                deleteButtonVisible:false
            })
        }
        }

    validateString(x) {
        if (typeof x === 'string' || x instanceof String) {
            if (x.length < 1) {
                return false
            }
        }
        return true
    }


    validateBoolean(x) {
        return typeof x == "boolean";
    }

    GenQuickMultiSearchDropdown(props) {
        let codeClass = this.props.storeHelper.getState().codesSelector.codes.codeClasses.find(cc => cc.name === props.dataName);
        let selectedId = -1;
        if (typeof props.selectedCodeId !== 'undefined') {
            selectedId = props.selectedCodeId;
        }
        if (typeof codeClass !== 'undefined') {
            let selectedItem = null;
            let choiceArray = codeClass.codes.map(code => {

                if (typeof code !== 'undefined' && typeof code.languages[code.languages.findIndex(i => i.languageName === this.props.t('common:GMLLanguage'))] !== 'undefined' && typeof code.codeId !== 'undefined') {

                    let codeNumber = code.languages.findIndex(i => i.languageName === this.props.t('common:GMLLanguage'))

                    if(code.languages[codeNumber].text.includes("Koodin kielisyys puuttuu")){
                        return {
                            id: -1,
                            name: 'not named',
                            title: '-1'
                        }
                    }
                    return {
                        id: code.codeId,
                        name: code.languages[codeNumber].text,
                        title: code.codeId.toString()
                    }

                } else {
                    return {
                        id: -1,
                        name: 'not named',
                        title: '-1'
                    }
                }
            }).filter(x=>x.id!==-1);


            selectedItem = [];

            if(Array.isArray(selectedId) && selectedId.length>0){
                selectedId.forEach(id => { selectedItem.push(choiceArray[choiceArray.findIndex(i => i.id === parseInt(id))]) })
            }

            if (!Array.isArray(selectedId) && selectedId !== -1) {
                selectedItem = choiceArray[choiceArray.findIndex(i => i.id === parseInt(selectedId))];
            }

            let required = true
            if (typeof props.required !== 'undefined') {
                required = props.required;
            }

            let maxChoices=1;
            if(typeof props.maxChoices!=='undefined'){
                maxChoices=props.maxChoices;
            }

            return (

                <MultiChoiceSearchDropdown required={required}
                                           maxChoices={maxChoices}
                                           active={props.active}
                                           minChoices={1}
                                           disabled={props.disabled}
                                           choices={props.dataLocation}
                                           validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                           title={this.props.t('addPlace:' + props.title)}
                                           tooltip={this.props.t('addPlace:' + props.tooltip)}
                                           minChoicesFixed={typeof props.minChoicesFixed!=='undefined'?props.minChoicesFixed:false}
                                           inputData={choiceArray}
                                           defaultSelected={typeof selectedItem !== 'undefined' && selectedItem !== null ? Array.isArray(selectedItem)? selectedItem : [selectedItem] : []}
                                           onValidityChange={(validity) => {

                                                let translatedDataName = props.dataName;

                                                if(props.dataName==='destinationFunder'){
                                                    translatedDataName='destinationFunderCodeId';
                                                }
                                                if(props.dataName==='destinationOwner'){
                                                    translatedDataName='destinationOwnerCodeId';
                                                }
                                                if(props.dataName==='destinationType'){
                                                    translatedDataName='destinationTypeCodeId';
                                                }

                                                this.setState({
                                                    destinationValid: {
                                                        ...this.state.destinationValid,
                                                        [translatedDataName]: validity
                                                    }
                                                })
                                           }
                                           }
                                           onChoicesChange={(choicesList) => {
                                               if (choicesList.length > 0) {
                                                   this.handleSelection(choicesList, codeClass)
                                               }
                                               if(choicesList.length===0 && codeClass.name==='customerServiceType'){
                                                   this.handleSelection([], codeClass)
                                               }
                                           }}

                />
            )
        } else {
            return (<>
                <label className="form-label" htmlFor={props.dataName}>{this.props.t('addPlace:' + props.title)}

                    {(typeof props.tooltip!=='undefined' && props.tooltip!=='') &&
                    <Tooltip title={props.tooltip}>
                        <IconButton>
                            <InfoIcon/>

                        </IconButton>
                    </Tooltip>
                    }
                </label>
                <input className="form-input" id={props.dataName}
                       placeholder={"Missing codeClass data for " + this.props.t('addPlace:' + "placeholder_" + props.dataName)}></input></>)
        }
    }

    handleSelection(choiceList, codeClass) {
        switch (codeClass.name) {
            case 'destinationType':
                this.setState(state => {
                    state.destination.destinationTypeCodeId = choiceList[0].id
                    state.destination.destinationTypeCodeClassId = codeClass.codeClassId
                    return state
                });
                break;
            case 'destinationOwner':
                this.setState(state => {
                    state.destination.destinationOwnerCodeId = choiceList[0].id
                    state.destination.destinationOwnerCodeClassId = codeClass.codeClassId
                    return state
                });
                break;

            case  'destinationFunder':
                this.setState(state => {
                    state.destination.destinationFunderCodeId = choiceList[0].id
                    state.destination.destinationFunderCodeClassId = codeClass.codeClassId
                    return state
                });
                break;

                case 'municipality':
                    this.setState(state => {
                        state.destination.municipalityCodeId = choiceList.map(v => v.id)
                        this.props.boundData.municipality = choiceList.map(v => v.id)
                        state.destination.municipalityCodeClassId = codeClass.codeClassId
                        return state
                    });
                    break;
                case 'county':
                    this.setState(state => {
                        state.destination.countyCodeId = choiceList.map(v => v.id)
                        this.props.boundData.county = choiceList.map(v => v.id)
                        state.destination.countyCodeClassId = codeClass.codeClassId
                        return state
                    });
                    break;
            case 'customerServiceType':
                if(choiceList.length === 0){
                    this.setState(state => {
                        state.destination.customerServiceTypeCodeId = 0
                        state.destination.customerServiceTypeCodeClassId = 0
                        return state
                    });
                }
                else{
                    this.setState(state => {
                        state.destination.customerServiceTypeCodeId = choiceList[0].id
                        state.destination.customerServiceTypeCodeClassId = codeClass.codeClassId
                        return state
                    });
                }
                break;
            default:
                console.log(`Not found ${codeClass.codeClassId}.`);
        }
    }

    GetLandUseUnitList (props) {

        let landUseUnitSelection = [];
        
        if (typeof this.state !== 'undefined' && this.state.landUseUnits.length > 0) {
            if (typeof this.state.landUseUnits !== 'undefined') {
                landUseUnitSelection = this.state.landUseUnits.map(x => {
                    {
                        return {id: parseInt(x.landUseUnitId),
                            name: x.landUseUnitName,
                            title: x.landUseUnitId.toString(),
                            icon: "chip"}
                    }
                });
            }
        

        let selectedLandUseUnit = [];

        if(this.state.selectedLandUseUnits.length>0){
            this.state.selectedLandUseUnits.forEach(landUseUnit => {
                var viewedLandUseUnit = landUseUnitSelection.find(f => f.id === landUseUnit.landUseUnitId);
                if(viewedLandUseUnit !== undefined){
                    selectedLandUseUnit.push(viewedLandUseUnit);
                }
            });
        } 
        
        if(this.state.selectedLandUseUnits.length === 0 &&
            this.state.destination.landUseUnitCodes !== undefined && this.state.destination.landUseUnitCodes !== null){
            this.state.destination.landUseUnitCodes.forEach(landUseUnit => {
                var viewedLandUseUnit = landUseUnitSelection.find(f => f.id === landUseUnit.LandUseUnitId);
                if(viewedLandUseUnit !== undefined){
                    selectedLandUseUnit.push(viewedLandUseUnit);
                }
            });
        }
        

        return <MultiChoiceSearchDropdown
                required={false}
                maxChoices={this.state.landUseUnits.length}
                active={true}
                minChoices={0}
                title={this.props.t('addPlace:land_use_unit_name')}
                tooltip={this.props.t('addPlace:tooltip_land_use_unit_name')}
                placeholder={this.props.t('addPlace:placeholder_land_use_unit_name')}
                inputData={landUseUnitSelection}
                validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                choices={selectedLandUseUnit}
                onChoicesChange={async (choicesList) => {
                    if (choicesList.length > 0) {
                        let fill = [];
                        choicesList.forEach(landUseUnit => {
                            let item = this.state.landUseUnits.find(f => f.landUseUnitId === landUseUnit.id);
                            if(item !== undefined){
                                fill.push(item);
                            }
                            this.setState({selectedLandUseUnits: fill});    
                            this.setState(state => {
                                state.destination.landUseUnitCodes = fill
                                return state
                            });
                        });

                        this.setState({pageUpdated: true});
                    }else{
                        this.setState({selectedLandUseUnits: [], clearLandUseUnits: true});
                        this.setState(state => {
                            state.destination.landUseUnitCodes = []
                            return state
                        });
                    }
                }}
                onValidityChanged={()=>{}}
                choiceAttrsShown={['id', 'name']}

            />
        }
    }

    resetData() {
        this.setState({
            nonce: crypto.randomUUID(),
            submitAttempted: false,
            destination: {
                ...this.state.destination,
                destinationName: "",
                description: "",
                inUse: false,
                destinationTypeCodeId: 0,
                destinationOwnerCodeId: 0,
                destinationFunderCodeId: 0,
                destinationServiceTypeCodeId: 0,
                parkDistrict: 0,
                regionalUnit: 0,
                entity: 0


            }
        });
    }

    async handleSubmit(event) {
        this.setState({isSubmitting: true})
        this.setState({submitAttempted: true});
        if (!this.state.editingState) {
            if (typeof event !== 'undefined') {
                event.preventDefault();
            }

            let invalidFields = [];
            for (const [k, v] of Object.entries(this.state.destinationValid)) {
                if (v === false) {
                    if (this.state.destinationRequired[k] === true) {
                        invalidFields.push(k);
                    }
                }
            }

            if (invalidFields.length > 0) {
                this.setState({isSubmitting:false, submitErrorsModalOpen:true, submitErrorsModalTitle:"", submitErrorsModalContent:this.props.t("common:one_or_more_required_field_is_invalid")});
                return false;
            }

            let submitThis = this.state.destination;
            submitThis.coordinate.x_Coordinate = this.props.boundData.coordinate[0];
            submitThis.coordinate.y_Coordinate = this.props.boundData.coordinate[1];
            submitThis.coordinate.n_Coordinate = this.props.boundData.latLon[1];
            submitThis.coordinate.e_Coordinate = this.props.boundData.latLon[0];
            submitThis.municipalityCodeId = typeof this.props.boundData.municipality !== 'undefined' && Array.isArray(this.props.boundData.municipality) ? Array.isArray(this.props.boundData.municipality) ? this.props.boundData.municipality : [this.props.boundData.municipality] : Array.isArray(this.props.boundData.munCodeId)?this.props.boundData.munCodeId:[this.props.boundData.munCodeId];
            submitThis.municipalityCodeClassId = parseInt(280);
            submitThis.countyCodeId = typeof this.props.boundData.county !== 'undefined' ? Array.isArray(this.props.boundData.county)?this.props.boundData.county:[this.props.boundData.county] : Array.isArray(this.props.boundData.cCodeId)?this.props.boundData.cCodeId:[this.props.boundData.cCodeId];
            submitThis.countyCodeClassId = parseInt(1005);
            submitThis.regionalUnitTypeCodeId = typeof this.props.boundData.regionalUnitTypeCodeId !== 'undefined' && this.props.boundData.regionalUnitTypeCodeId !== null ? this.props.boundData.regionalUnitTypeCodeId : -1;
            submitThis.regionalUnitTypeCodeClassId = parseInt(350);
            submitThis.parkDistrictCodeId = typeof this.props.boundData.parkDistrictTypeCodeId !== 'undefined' && this.props.boundData.parkDistrictTypeCodeId !== null ? this.props.boundData.parkDistrictTypeCodeId : -1;
            submitThis.parkDistrictCodeClassId = parseInt(1004);
            submitThis.entityCodeId = typeof typeof this.props.boundData.entityTypeCodeId !== 'undefined' && this.props.boundData.entityTypeCodeId !== null ? this.props.boundData.entityTypeCodeId : -1;
            submitThis.entityCodeClassId = parseInt(130);
            submitThis.landUseUnitIds = typeof this.state.selectedLandUseUnits !== 'undefined' && this.state.selectedLandUseUnits !== null ? this.state.selectedLandUseUnits : -1;
            submitThis.nonce=this.state.nonce;

            try
            {
                let response = await InsertDestination(submitThis);

                if (response instanceof AxiosError) {
                    this.setState({submitErrorsModalOpen:true, submitErrorsModalTitle:this.props.t("common:submit_error_title"), submitErrorsModalContent:this.props.t("common:submit_error_content") + response.message});
                    this.setVisibility(true);
                } else {
                   this.setState({editSuccesfulModalOpen:true})
                }
            } catch (error) {
                console.log(error);
            }
        } else {

            let updateModel = {};

            if (this.state.destination.destinationName !== null && this.state.destination.destinationName !== '') {
                updateModel = {...updateModel, destinationName: this.state.destination.destinationName}
            }
            if (this.state.destination.description !== null) {
                updateModel = {...updateModel, description: this.state.destination.description === '' ? this.props.boundData.description : this.state.destination.description};
            }
            if (this.state.destination.customerServiceName !== null) {
                updateModel = {...updateModel, customerServiceName: this.state.destination.customerServiceName === '' ? this.props.boundData.customerServiceName !== null ? this.props.boundData.customerServiceName : '' : this.state.destination.customerServiceName !== null ? this.state.destination.customerServiceName : ''};
            }
            updateModel = {...updateModel, inUse: this.state.destination.inUse};

            if (this.state.destination.destinationFunderCodeId !== null && this.state.destination.destinationFunderCodeId !== 0 && this.props.boundData.destinationFunderCodeId !== this.state.destination.destinationFunderCodeId) {
                updateModel = {
                    ...updateModel,
                    destinationFunderCodeId: this.state.destination.destinationFunderCodeId,
                    destinationFunderCodeClassId: this.state.destination.destinationFunderCodeClassId
                };
            }


            if (this.state.destination.destinationOwnerCodeId !== null && this.state.destination.destinationOwnerCodeId !== 0 && this.props.boundData.destinationOwnerCodeId !== this.state.destination.destinationOwnerCodeId) {
                updateModel = {
                    ...updateModel,
                    destinationOwnerCodeId: this.state.destination.destinationOwnerCodeId,
                    destinationOwnerCodeClassId: this.state.destination.destinationOwnerCodeClassId
                };
            }

            if (this.state.destination.customerServiceTypeCodeId !== null ) {
                updateModel = {
                    ...updateModel,
                    customerServiceTypeCodeId: this.state.destination.customerServiceTypeCodeId,
                    customerServiceTypeCodeClassId: this.state.destination.customerServiceTypeCodeClassId
                };
            }

            if (this.state.destination.destinationTypeCodeId !== null && this.state.destination.destinationTypeCodeClassId !== 0 && this.props.boundData.destinationTypeCodeId !== this.state.destination.destinationTypeCodeId) {
                updateModel = {
                    ...updateModel,
                    destinationTypeCodeId: this.state.destination.destinationTypeCodeId,
                    destinationTypeCodeClassId: this.state.destination.destinationTypeCodeClassId
                };
            }
            if (this.state.destination.municipalityCodeId !== null && this.state.destination.municipalityCodeClassId !== 0) {
                updateModel = {
                    ...updateModel,
                    municipalityCodeId: this.state.destination.municipalityCodeId,
                    municipalityCodeClassId: this.state.destination.municipalityCodeClassId
                };
            }

            if(typeof this.state.destination.municipalityCodeId !== 'undefined' && this.state.destination.municipalityCodeId !== null && this.state.destination.municipalityCodeId.length > 0){
                updateModel = {
                    ...updateModel,
                    municipalityCodeId: this.state.destination.municipalityCodeId
                };
            }

            if((typeof this.state.destination.municipalityCodeId === 'undefined' || this.state.destination.municipalityCodeId === null || this.state.destination.municipalityCodeId.length == 0) &&
                typeof this.state.destination.municipality !== 'undefined' && this.state.destination.municipality !== null && this.state.destination.municipality.length > 0){
                updateModel = {
                    ...updateModel,
                    municipalityCodeId: this.state.destination.municipality
                };
            }

            

            if(this.state.destination.municipality === undefined || (this.state.destination.municipalityCodeId !== undefined && this.state.destination.municipalityCodeId !== null && this.state.destination.municipalityCodeId.length === 0) &&
                typeof this.props.boundData.municipality !== 'undefined' && this.props.boundData.municipality !== null && this.props.boundData.municipality.length > 0){
                    updateModel = {
                        ...updateModel,
                        municipalityCodeId: this.props.boundData.municipality
                    };
                }

            if (this.state.destination.countyCodeId !== null && this.state.destination.countyCodeClassId !== 0) {
                updateModel = {
                    ...updateModel,
                    countyCodeId: this.state.destination.countyCodeId,
                    countyCodeClassId: this.state.destination.countyCodeClassId
                };
            }

            if (typeof this.state.destination.landUseUnitCodes !== 'undefined' && this.state.destination.landUseUnitCodes !== null && this.state.destination.landUseUnitCodes.length > 0) {
                updateModel = {
                    ...updateModel,
                    landUseUnitIds: this.state.destination.landUseUnitCodes
                };
            }

            if (typeof this.state.destination.landUseUnitCodes !== 'undefined' && this.state.destination.landUseUnitCodes !== null && this.state.destination.landUseUnitCodes.length == 0) {
                updateModel = {
                    ...updateModel,
                    landUseUnitIds: []
                };
            }

            if (typeof this.state.selectedLandUseUnits !== 'undefined' && this.state.selectedLandUseUnits !== null && this.state.selectedLandUseUnits.length > 0) {
                updateModel = {
                    ...updateModel,
                    landUseUnitIds: this.state.selectedLandUseUnits
                };
            }

            if (typeof this.state.selectedLandUseUnits !== 'undefined' && this.state.selectedLandUseUnits !== null && this.state.selectedLandUseUnits.length === 0 && this.state.clearLandUseUnits === true) {
                updateModel = {
                    ...updateModel,
                    landUseUnitIds: []
                };
            }

            if ( this.state.clearLandUseUnits === true) {
                updateModel = {
                    ...updateModel,
                    clearLandUseUnit: this.state.clearLandUseUnits
                };
            }

            updateModel = {
                ...updateModel,
                modifiedBy: typeof this.props.instance.getAllAccounts() !== 'undefined' && this.props.instance.getAllAccounts().length > 0 ? this.props.instance.getAllAccounts()[0].name : '',
            };

            //special case there are couple of situtations when migration is not gathered municipality for destiantion then ask it from user
           if((this.state.destination.municipalityCodeId !== undefined && this.state.destination.municipalityCodeId.length === 0) && (
            this.props.boundData !== undefined && this.props.boundData.municipality !== undefined && this.props.boundData.municipality.length === 0))
            {
                this.setState({showSubmitAlert: true, isSubmitting: false});
                return false;
           }else{
                //for some reason when modified from list there was [null]
                if(updateModel.municipalityCodeId.length === 1 && updateModel.municipalityCodeId[0] === null){
                    this.setState({showSubmitAlert: true, isSubmitting: false});
                    return false;
                }

                console.log('updateModel ', updateModel);    

                let response = await UpdateDestination((typeof this.props.boundData.destinationId!=='undefined'?this.props.boundData.destinationId:this.props.params.id), updateModel);

                if (response instanceof AxiosError) {
                    this.setState({submitErrorsModalOpen:true, submitErrorsModalTitle:this.props.t("common:submit_error_title"), submitErrorsModalContent:this.props.t("common:submit_error_content") + response.message});
                    if(typeof this.props.boundData.loadDestination !== 'undefined') {
                        this.setVisibility(true);
                    }
                } else {
                    this.setState({editSuccesfulModalOpen:true})
                }

                this.setState({editingState: false, clearLandUseUnits: false});
            }
        }


        if(typeof this.props.boundData.loadDestination !== 'undefined') {
            await this.props.boundData.loadDestination();
            this.setState({editSuccesfulModalOpen:true, isSubmitting:false})
            this.props.boundData.clearMapSelection();

        }else{
            await this.loadData();
            this.setState({editSuccesfulModalOpen:true, isSubmitting:false})
            
        }

    }

    async deleteDestination() {
        this.setState({isSubmitting: true})
        if (this.state.destination.destinationId!==0 || typeof this.props.boundData.destinationId !== 'undefined' && this.props.boundData.destinationId !== null) {

            let response;
            if(this.state.destination.destinationId!==0){
                response = await DeleteDestination(this.state.destination.destinationId);

            }else{
                response = await DeleteDestination(this.props.boundData.destinationId);
            }


            if (response instanceof AxiosError) {
                this.setState({submitErrorsModalOpen:true, submitErrorsModalTitle:this.props.t("common:submit_error_title"), submitErrorsModalContent:this.props.t("common:submit_error_content") + response.message});
                this.setVisibility(true);
            } else {
                this.setState({deletionSuccesfullModalOpen:true})
            }

        }
    }

    setVisibility(visibility) {
        this.props.onVisibilityChanged([visibility, true]);
    }

    handleCoords(event) {
        const handler = new RegExp("^(?!\\s*$)?[[0-9]*[.,]?[0-9]*$");
        if (handler.test(event.target.value)) {
            let coords = this.state.destination.coordinate;
            coords[event.target.name] = event.target.value.toString().replace(",", ".");
            this.setState({destination: {coordinate: coords}})

        } else {
            throw new TypeError("Coordinate not solvable to float");
        }
    }

    async checkDependencies() {


        let deleteAllowed = false;
        await DestinationDeleteAllowed(this.state.destination.destinationId).then(x=> {
            if(x.data===true){
                deleteAllowed=true
            }
        })

        if(deleteAllowed){
            await this.deleteDestination();
        }else{
            this.setState({destinationDeletionNotAllowedVisible: true})
        }

        this.setState({isSubmitting:false})

    }


    render() {
        let {t} = this.props;

        return (
            typeof this.props.boundData["siteName"]==='undefined' &&
            <>

                <ModalComponent
                    showModal={this.state.editSuccesfulModalOpen}
                    title={t("edit_succesful_title")}
                    content={t("edit_succesful_content")}
                    modalType={"errorModal"}
                    onModalContinue={() => {
                        this.setState({viewMode: 1, editSuccesfulModalOpen:false}, async ()=>{
                           await this.loadData();
                        });
                    }}
                />

                <ModalComponent
                    showModal={this.state.submitErrorsModalOpen}
                    title={this.state.submitErrorsModalTitle}
                    content={this.state.submitErrorsModalContent}
                    modalType={"errorModal"}
                    onModalContinue={() => {
                        this.setState({viewMode: 1, submitErrorsModalOpen:false});
                    }}
                />

                <ModalComponent
                    showModal={this.state.deletionSuccesfullModalOpen}
                    title={t("destination_deletion_succesful_title")}
                    content={t("destination_deletion_succesful_content")}
                    modalType={"errorModal"}
                    onModalContinue={() => {
                        this.setState({deletionSuccesfullModalOpen:false}, async ()=>{
                            this.props.navigate("/destinations/add");
                            window.location.reload();
                        });
                    }}

                />
                <ModalComponent
                    showModal={this.state.destinationDeletionNotAllowedVisible}
                    title={t("destination_deletion_not_allowed_title")}
                    content={t('destination_deletion_not_allowed_content')}
                    modalType={"errorModal"}
                    onModalContinue={() => {
                        this.setState({destinationDeletionNotAllowedVisible:false})
                    }}
                />

                <ModalComponent showModal={this.state.showErrorModal}
                                title={t("delete_destination_button")}
                                content={t("delete_destination_description")}
                                modalType={"submitModal"}
                                onModalContinue={async () => {
                                    this.setState({showErrorModal: false, isSubmitting:true});
                                    await this.checkDependencies();
                                }}
                                onModalCancel={() => {
                                    this.setState({showErrorModal: false})
                                }}
                />


                <ModalComponent showModal={this.state.showEditingModal}
                                title={t('title_editingModal')}
                                content={t('content_editingModal')}
                                modalType={"submitModal"}
                                onModalContinue={() => {
                                    this.setState({showEditingModal: false});
                                    this.handleSubmit();
                                }}
                                onModalCancel={() => {
                                    this.setState({showEditingModal: false})
                                }}
                />


                {this.state.viewMode === 1 ?
                    <>
                        <div style={{marginBottom: "2em"}}>
                            
                            <Button  size={"small"} variant={"contained"}  className="float-left"
                                disabled={localStorage.getItem("user-role") === "Nasta.Administrator" ? false : true}
                                    onClick={() => this.setState({
                                        viewMode: 2,
                                        editingState: true
                                    })}>
                                <img style={{filter:"invert(100%)", height:"1.5vh"}} src="/img/icon/JHT design system/Pencil Write Alternate.svg" alt={t("common:button_edit")}/>{t("button_edit")}
                            </Button>
                             { 
                                <Button aria-label={t('common:close')} size={"small"}  variant={"contained"}   className="float-right btn-no-style" onClick={() => {
                                    if (this.props.location.pathname.startsWith('/destinations/add')) {
                                        this.setState({viewMode: 1});
                                        this.setVisibility(false);
                                    }
                                    else if(this.props.location.pathname.startsWith('/destination/view') || this.props.location.pathname.startsWith('/destinations/')){
                                        this.props.navigate(-1);
                                    } 
                                    else {
                                        if (this.props.boundData && typeof this.props.boundData.clearMapSelection === 'function') {
                                            this.props.boundData.clearMapSelection();
                                        }
                                        this.setVisibility(false);
                                    }
                                }}>
                                     <img alt={""}
                                          src={close_fill}
                                          disabled={localStorage.getItem("user-role") === "Nasta.Administrator" ? false : true}
                                          id="close-add-destination"   />
                                </Button>
                            }
                        </div>
                        <ViewPlace destination={{...this.props.boundData, ...(this.state.destination.destinationId!==0&&this.state.destination)}}/>
                    </> : <>
                        <Button size={"small"}  variant={"contained"} aria-label={t("common:close")} className="float-right btn-no-style" onClick={() => {

                                if (this.props.location.pathname.startsWith('/destinations/add')) {
                                    this.setState({viewMode: 1});
                                    this.setVisibility(false);
                                }
                                else if (this.props.location.pathname.startsWith('/destination/view')) {
                                    this.setState({viewMode: 1});
                                }
                                else if(this.props.location.pathname.startsWith('/destinations/')){
                                    if(this.state.viewMode === 2){
                                        this.setState({viewMode: 1});
                                    }else{
                                        this.props.navigate(-1);
                                    }
                                } 
                                else {
                                    if (this.props.boundData && typeof this.props.boundData.clearMapSelection === 'function') {
                                        this.props.boundData.clearMapSelection();
                                    }
                                    this.setVisibility(false);
                                }

                                }}>
                            <img aria-label={t('common:close')}  alt={""} src={close_fill} disabled={localStorage.getItem("user-role") === "Nasta.Administrator" ? false : true}

                                    id="close-add-destination"   />
                        </Button>



                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                {this.props.t('addPlace:input_tooltip_customer_place_id')!=='' &&
                                <label className="form-label"
                                       htmlFor={"destinationId"}>{this.props.t('addPlace:input_example_customer_place_id') + (!this.state.destinationTouched.destinationId ? ' *' : '')}



                                    <Tooltip title={this.props.t('addPlace:input_tooltip_customer_place_id')}>
                                        <IconButton>
                                            <InfoIcon/>

                                        </IconButton>
                                    </Tooltip>
                                </label>}



                                <p id={"destinationId"}>{(this.state.destination.destinationId === 0 ?
                                    typeof this.props.boundData !== 'undefined' && typeof this.props.boundData.destinationId !== 'undefined'
                                        ? this.props.boundData.destinationId
                                        : this.props.t('addPlace:word_forms_on_save')
                                    : this.state.destination.destinationId)}</p>
                            </div>

                            <ValidatedInputField
                                name={"destinationName"}
                                fieldName={this.props.t('addPlace:destination_name')}
                                tooltip={this.props.t('addPlace:tooltip_destination_name')}

                                required={true}
                                minLength={2}
                                maxLength={100}
                                type={"text"}
                                disabled={false}
                                value={typeof this.state.destination.destinationName!=='undefined'&&this.state.destination.destinationName!==''?this.state.destination.destinationName:( typeof this.props.boundData.destinationName !== 'undefined' ? this.props.boundData.destinationName : '')}
                                fieldId={'destinationName'}
                                validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}

                                validatorFunction={() => {
                                    return true
                                }}
                                onFieldValueChanged={(newValue) => {
                                    this.setState({destination: {...this.state.destination, destinationName: newValue}});
                                    this.props.boundData.destinationName = newValue;
                                }}
                                onFieldValidityChanged={(validity) => {
                                    this.setState({
                                        destinationValid: {
                                            ...this.state.destinationValid,
                                            destinationName: validity
                                        }
                                    })
                                }}
                            />
                            <this.GenQuickMultiSearchDropdown active={true} dataName="destinationType"
                                                              required={true}
                                                              tooltip={"tooltip_destinationType"}
                                                              title={"destinationType"}
                                                              selectedCodeId={typeof this.state.destination?.destinationTypeCodeId!=='undefined'&&this.state.destination?.destinationTypeCodeId>0?this.state.destination.destinationTypeCodeId:( typeof this.props.boundData.destinationTypeCodeId !== 'undefined' ? this.props.boundData.destinationTypeCodeId : undefined)}/>
                            <this.GenQuickMultiSearchDropdown active={true} dataName="destinationOwner"
                                                              required={true}
                                                              tooltip={"tooltip_destinationOwner"}
                                                              title={"destinationOwner"}
                                                              selectedCodeId={typeof this.state.destination?.destinationOwnerCodeId!=='undefined'&&this.state.destination?.destinationOwnerCodeId>0?this.state.destination.destinationOwnerCodeId:(typeof this.props.boundData.destinationOwnerCodeId !== 'undefined' ? this.props.boundData.destinationOwnerCodeId : undefined)}/>
                            <this.GenQuickMultiSearchDropdown active={true} dataName="destinationFunder"
                                                              required={true}
                                                              placeholder={"placeholder_destinationFunder"}
                                                              tooltip={"tooltip_destinationFunder"}
                                                              title={"destinationFunder"}
                                                              selectedCodeId={typeof this.state.destination?.destinationFunderCodeId!=='undefined'&&this.state.destination.destinationFunderCodeId>0?this.state.destination.destinationFunderCodeId:(typeof this.props.boundData.destinationFunderCodeId !== 'undefined' ? this.props.boundData.destinationFunderCodeId : undefined)}/>

                            <ValidatedInputField
                                name={"destinationDescription"}
                                fieldName={this.props.t('addPlace:destination_description')}
                                tooltip={this.props.t('addPlace:tooltip_destination_description')}
                                placeholder={this.props.t('addPlace:placeholder_destination_description')}
                                required={false}
                                minLength={0}
                                maxLength={1000}
                                multiline={true}
                                type={"text"}
                                fieldId={'description'}
                                validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                value={typeof this.state.destination.description!=='undefined'&&this.state.destination.description!==''?this.state.destination.description:( typeof this.props.boundData.description !== 'undefined' ? this.props.boundData.description : '')}
                                validatorFunction={() => {
                                    return true
                                }}
                                onFieldValueChanged={(newValue) => {
                                    this.setState({destination: {...this.state.destination, description: newValue}});
                                    this.props.boundData.description = newValue;
                                }}
                                onFieldValidityChanged={(validity) => {
                                    this.setState({
                                        destinationValid: {
                                            ...this.state.destinationValid,
                                            description: validity
                                        }
                                    })
                                }}
                            />

                            <div className="form-group">
                                <label className="form-checkbox" htmlFor={"inUse-label"}>
                                    <input id={"inUse-label"} onChange={(e) => {
                                        this.setState({
                                            destination: {
                                                ...this.state.destination,
                                                inUse: e.target.checked
                                            }
                                        });
                                        if (typeof this.props.boundData.inUse !== 'undefined') {
                                            this.props.boundData.inUse = e.target.checked
                                        }
                                    }} type="checkbox"
                                           checked={typeof this.props.boundData.inUse !== 'undefined' ? this.props.boundData.inUse : this.state.destination.inUse}></input>
                                    <i className="form-icon"></i> <span>{this.props.t('addPlace:inUse')}</span>
                                </label>
                            </div>



                                <ValidatedInputField
                                    name={"customerServiceName"}
                                    fieldName={this.props.t('addPlace:customer_service_point_name')}
                                    tooltip={this.props.t('addPlace:tooltip_customer_service_point_name')}
                                    placeholder={this.props.t('addPlace:placeholder_customer_service_point_name')}
                                    required={false}
                                    minLength={0}
                                    maxLength={100}
                                    type={"text"}
                                    disabled={false}
                                    fieldId={'customerServiceName'}
                                    validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                    value={this.state.destination.customerServiceName === '' || typeof this.state.destination.customerServiceName === 'undefined' ? typeof this.props.boundData.customerServiceName !== 'undefined' ? this.props.boundData.customerServiceName : '' : this.state.destination.customerServiceName}
                                    validatorFunction={() => {
                                        return true
                                    }}

                                    onFieldValueChanged={(newValue) => {
                                        this.setState({destination: {...this.state.destination, customerServiceName: newValue}});
                                        this.props.boundData.customerServiceName = newValue;
                                    }}

                                    onFieldValidityChanged={() => {
                                        return true;}
                                    }
                                />

                            <this.GenQuickMultiSearchDropdown active={true} required={false}
                                                              dataName="customerServiceType"
                                                              placeholder={"placeholder_customerServiceType"}
                                                              tooltip={"tooltip_customerServiceType"}
                                                              title={"customerServiceType"}
                                                              selectedCodeId={typeof this.state.destination?.customerServiceTypeCodeId!=='undefined'&&this.state.destination?.customerServiceTypeCodeId>0?this.state.destination.customerServiceTypeCodeId:(typeof this.props.boundData.customerServiceTypeCodeId !== 'undefined' ? this.props.boundData.customerServiceTypeCodeId : undefined)}/>
                     
                            <this.GetLandUseUnitList/>

                            <this.GenQuickMultiSearchDropdown
                                required={false}
                                minChoicesFixed={true}
                                disabled={true}
                                minChoices={this.state.selectedLandUseUnits.length}
                                active={false} dataName="landUseUnitType"
                                placeholder={"placeholder_land_use_unit_type"} tooltip={"tooltip_land_use_unit_type"} title={"land_use_unit_type"}
                                selectedCodeId={typeof this.state.destination !== 'undefined' && typeof this.state.destination.landUseUnitTypeCodes !== 'undefined' ? this.state.destination.landUseUnitTypeCodes :
                                    typeof this.props.boundData!== 'undefined' && typeof this.props.boundData.landUseUnitTypeCodes !== 'undefined' && this.props.boundData.landUseUnitTypeCodes !== -1 ? this.props.boundData.landUseUnitTypeCodes : ''}/>

                            <this.GenQuickMultiSearchDropdown
                                minChoicesFixed={this.state.editingState === true ||typeof this.props.boundData.munCodeId !== 'undefined'}
                                active={false} dataName="entity"
                                placeholder={"placeholder_entity"}  tooltip={"tooltip_entity"} title={"entity"}
                                selectedCodeId={typeof this.state.destination.entityTypeCodeId !== 'undefined' && this.state.destination.entityTypeCodeId !== -1 && this.state.destination.entityTypeCodeId > 0 ? this.state.destination.entityTypeCodeId :
                                    typeof this.props.boundData !== 'undefined' && typeof this.props.boundData.entityTypeCodeId !== 'undefined' ? this.props.boundData.entityTypeCodeId : -1}/>
                            <this.GenQuickMultiSearchDropdown
                                minChoicesFixed={this.state.editingState === true ||typeof this.props.boundData.munCodeId !== 'undefined'}
                                active={false} dataName="parkDistrict"
                                placeholder={"placeholder_parkDistrict"}  tooltip={"tooltip_parkDistrict"} title={"parkDistrict"}
                                selectedCodeId={typeof this.state.destination.parkDistrictTypeCodeId !== 'undefined' && this.state.destination.parkDistrictTypeCodeId !== -1 && this.state.destination.parkDistrictTypeCodeId > 0 ? this.state.destination.parkDistrictTypeCodeId :
                                    typeof this.props.boundData !== 'undefined' && typeof this.props.boundData.parkDistrictTypeCodeId !== 'undefined' ? this.props.boundData.parkDistrictTypeCodeId : -1}/>
                            <this.GenQuickMultiSearchDropdown
                                minChoicesFixed={this.state.editingState === true ||typeof this.props.boundData.munCodeId !== 'undefined'}
                                active={false} dataName="regionalUnit"
                                placeholder={"placeholder_regional_unit"}  tooltip={"tooltip_regional_unit"} title={"regional_unit"}
                                selectedCodeId={typeof this.state.destination.regionalUnitTypeCodeId !== 'undefined' && this.state.destination.regionalUnitTypeCodeId !== -1 && this.state.destination.regionalUnitTypeCodeId > 0 ? this.state.destination.regionalUnitTypeCodeId :
                                    typeof this.props.boundData !== 'undefined' && typeof this.props.boundData.regionalUnitTypeCodeId !== 'undefined' ? this.props.boundData.regionalUnitTypeCodeId : -1}/>


                            <this.GenQuickMultiSearchDropdown active={this.state.editingState === true ||typeof this.props.boundData.munCodeId !== 'undefined'}
                                                              maxChoices={10}
                                                              dataName="municipality"
                                                              minChoicesFixed={true}
                                                              placeholder={"placeholder_municipality"}
                                                              tooltip={"tooltip_municipality"} title={"municipality"}
                                                              selectedCodeId={typeof this.state.destination.municipality !== 'undefined' && this.state.destination.municipality.length > 0 ? this.state.destination.municipality :
                                                                typeof this.props.boundData !== 'undefined' && typeof this.props.boundData.munCodeId !== 'undefined' ? this.props.boundData.munCodeId : this.props.boundData.municipality}/>
                            <this.GenQuickMultiSearchDropdown active={false}
                                                              maxChoices={10}
                                                              disabled={true}
                                                              minChoicesFixed={this.state.editingState === true ||typeof this.props.boundData.munCodeId !== 'undefined'}
                                                              dataName="county" placeholder={"placeholder_county"}
                                                              tooltip={"tooltip_county"} title={"county"}
                                                              selectedCodeId={typeof this.state.destination.county !== 'undefined' && this.state.destination.county.length > 0 ? this.state.destination.county :
                                                                typeof this.props.boundData !== 'undefined' && typeof this.props.boundData.cCodeId !== 'undefined'  ? this.props.boundData.cCodeId : this.props.boundData.county}/>

                            <div className="form-group">
                                <label htmlFor={"x_Coordinate"} className="form-label">{this.props.t('addPlace:coordinate_X')}
                                    <Tooltip title={this.props.t('addPlace:tooltip_coordinate_X')}>
                                        <IconButton>
                                            <InfoIcon/>

                                        </IconButton>
                                    </Tooltip>
                                </label>
                                <p id={"x_Coordinate"}>{typeof this.state.destination.coordinate !== 'undefined' && typeof this.state.destination.coordinate.x_Coordinate !== 'undefined' && parseInt(this.state.destination.coordinate.x_Coordinate) > 0 ? parseInt(this.state.destination.coordinate.x_Coordinate):
                                    typeof this.props.boundData !== 'undefined' && typeof this.props.boundData.coordinate !== 'undefined' && typeof this.props.boundData.coordinate[0] !== 'undefined' ? parseInt(this.props.boundData.coordinate[0]) : typeof this.props.boundData.coordinate !== 'undefined' ? parseInt(this.props.boundData.coordinate.x_Coordinate) : ''}</p>

                                <label htmlFor={"y_Coordinate"} className="form-label">{this.props.t('addPlace:coordinate_Y')}
                                    <Tooltip title={this.props.t('addPlace:tooltip_coordinate_Y')}>
                                        <IconButton>
                                            <InfoIcon/>

                                        </IconButton>
                                    </Tooltip>
                                </label>

                                <p id={"y_Coordinate"}>{typeof this.state.destination.coordinate !== 'undefined' && typeof this.state.destination.coordinate.y_Coordinate !== 'undefined' && parseInt(this.state.destination.coordinate.y_Coordinate) > 0 ? parseInt(this.state.destination.coordinate.y_Coordinate):
                                    typeof this.props.boundData !== 'undefined' && typeof this.props.boundData.coordinate !== 'undefined' && typeof this.props.boundData.coordinate[1] !== 'undefined' ? parseInt(this.props.boundData.coordinate[1]) : typeof this.props.boundData.coordinate !== 'undefined' ? parseInt(this.props.boundData.coordinate.y_Coordinate) : ''}</p>

                                <label htmlFor={"n_Coordinate"} className="form-label">{this.props.t('addPlace:coordinate_N')}
                                    <Tooltip title={this.props.t('addPlace:tooltip_coordinate_N')}>
                                        <IconButton>
                                            <InfoIcon/>

                                        </IconButton>
                                    </Tooltip>
                                </label>

                                <p id={"n_Coordinate"}>{typeof this.state.destination.coordinate !== 'undefined' && typeof this.state.destination.coordinate.n_Coordinate !== 'undefined' && parseFloat(this.state.destination.coordinate.n_Coordinate) > 0 ? parseFloat(this.state.destination.coordinate.n_Coordinate).toFixed(8):
                                        typeof this.props.boundData !== 'undefined' && typeof this.props.boundData.latLon !== 'undefined' ? parseFloat(this.props.boundData.latLon[1]).toFixed(8) : typeof this.props.boundData.coordinate !== 'undefined' ? parseFloat(this.props.boundData.coordinate.n_Coordinate).toFixed(8) : ''}</p>

                                <label htmlFor={"e_Coordinate"} className="form-label">{this.props.t('addPlace:coordinate_E')}
                                    <Tooltip title={this.props.t('addPlace:tooltip_coordinate_E')}>
                                        <IconButton>
                                            <InfoIcon/>

                                        </IconButton>
                                    </Tooltip>
                                </label>

                                <p id={"e_Coordinate"}>{typeof this.state.destination.coordinate !== 'undefined' && typeof this.state.destination.coordinate.e_Coordinate !== 'undefined' && parseFloat(this.state.destination.coordinate.e_Coordinate) > 0 ? parseFloat(this.state.destination.coordinate.e_Coordinate).toFixed(8):
                                    typeof this.props.boundData !== 'undefined' && typeof this.props.boundData.latLon !== 'undefined' ? parseFloat(this.props.boundData.latLon[0]).toFixed(8) : typeof this.props.boundData.coordinate !== 'undefined' ? parseFloat(this.props.boundData.coordinate.e_Coordinate).toFixed(8) : ''}</p>
                            </div>

                            <div className="form-group">
                                <label  className="form-label"
                                       htmlFor={"createdBy"}>{this.props.t('addPlace:destination_creator')}
                                    <Tooltip title={this.props.t('addPlace:destination_creator')}>
                                        <IconButton>
                                            <InfoIcon/>

                                        </IconButton>
                                    </Tooltip>
                                </label>


                                <p>{typeof this.props.boundData !== 'undefined' && typeof this.props.boundData.createdBy !== 'undefined' ? this.props.boundData.createdBy : typeof this.state.destination !== 'undefined' && typeof this.state.destination.createdBy !== 'undefined' && this.state.destination.createdBy }</p>
                            </div>
                            <div className="form-group">
                                <label className="form-label"
                                       htmlFor={"destinationModifier"}>{this.props.t('addPlace:destination_modifier')}
                                    <Tooltip title={this.props.t('addPlace:destination_modifier')}>
                                        <IconButton>
                                            <InfoIcon/>

                                        </IconButton>
                                    </Tooltip>
                                </label>

                               <p>{typeof this.props.instance.getAllAccounts() !== 'undefined' && this.props.instance.getAllAccounts().length > 0 ? this.props.instance.getAllAccounts()[0].name : ''}</p>
                            </div>

                            {        this.state.showSubmitAlert &&  <Alert onClose={() => {this.setState({showSubmitAlert: false})}} severity="error">
                                                <AlertTitle>{t("addPlace:error_message_header")}</AlertTitle>
                                                {t("addPlace:error_message_title_alert_box")} — <strong>{t("addPlace:error_message_add_municipality_and_county")}</strong>
                                            </Alert>
                            }

                            {
                                this.state.viewMode === 1 ?
                                    <Button size={"small"}  variant={"contained"} type="button" onClick={() => this.resetData()}
                                            className=" float-left">{t("addPlace:button_cancel")}
                                    </Button> :

                                    this.state.deleteButtonVisible &&
                                    <Button size={"small"}  variant={"contained"} type="button" onClick={() => this.setState({showErrorModal: true})}
                                    disabled={(localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser")?!this.state.canBeDeleted:true}
                                    className=" float-left">
                                        <img style={{filter:"invert(100%)", height:"1.5vh"}}
                                      src="/img/icon/JHT design system/bin_fill.svg"
                                      alt={t("common:delete_button")}/>
                                        {t("common:delete_button")}
                                    </Button>

                            }


                            <Button  size={"small"}  variant={"contained"} type="button"
                            disabled={localStorage.getItem("user-role") === "Nasta.Administrator" ? false : true}
                            onClick={() => {
                                if (this.state.editingState) {
                                    this.setState({showEditingModal: true})
                                } else {
                                    this.handleSubmit()
                                }

                            }}
                                    className="float-right"><img alt={""} style={{filter:"invert(100%)", height:"1.5vh"}} src="/img/icon/JHT design system/check_fill.svg"/> {t("button_save")}
                            </Button>

                             <UploadingNotification showModal={this.state.isSubmitting} errors={this.state.errors} type={"upload"} />


                        </form>

                       
                    </>
                }
            </>
        )
    }
}

export default withRouter(AddPlace);